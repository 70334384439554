/* CSS for QuestionPage component */

/* Container */
.container-fluid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}

.question-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}

/* Content */
.question-content {
  flex-grow: 1;
  margin-right: 20px;
  max-width: 600px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.navigation-bar {
  width: 100%;
  max-width: 600px;
  overflow-y: auto;
  max-height: 200px;
  padding: 5px;
  display: flex;
  gap: 5px;
}

.navigation-button {
  width: 30px;
  height: 30px;
  padding: 10px;
  margin: 1px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: black;
  background-color: white;
}

.navigation-button.active {
  background-color: green;
  color: white;
}

.question-header {
  text-align: left;
}

.voice-button {
  font-size: 14px;
  margin-left: 10px;
}

.question-text {
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.question-text-bold {
  text-align: left;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.question-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.options-form {
  text-align: left;
}

.option-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.option-item .form-check-input {
  vertical-align: middle;
  margin-right: 5px;
  border: 1px solid black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 2px;
}

.answer-explanation-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
}

.explanation-text-container {
  flex-grow: 1;
  width: 100%;
}

.answer-image-container {
  width: 100%;
  max-width: 100%;
}

.answer-image {
  width: 100%;
  max-width: 50%;
  height: auto;
  margin-top: 10px;
}

.explanation-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
  .container-fluid {
    padding-top: 20px;
  }

  .question-content {
    margin-right: 0;
    width: 100%;
    max-width: none;
  }

  .navigation-bar {
    max-width: none;
    width: 100%;
  }

  .answer-image {
    width: 100%;
    max-width: 100%;
  }

  .voice-button {
    font-size: 14px;
    margin-left: 10px;
  }
}
