.footer-info {
  background-color: #040047;
  padding: 20px 20px; /* Reduced padding for smaller screens */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: '"Libre Baskerville", serif';
}

.footer-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  font-family: '"Libre Baskerville", serif';

}

.footer-container > div {
  flex: 1; /* Maintain equal spacing */
  margin: 10px;
  min-width: 250px; /* Minimum width for each section */
  color: #868c92;
  font-family: '"Libre Baskerville", serif';


}

.about-us h2,
.social-media h2,
.faq-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: '"Libre Baskerville", serif';

}

.about-us p,
.faq-section ul,
.faq-section p,
.social-media .icons {
  font-size: 1rem;
  font-family: '"Libre Baskerville", serif';

}

.social-media .icons a {
  margin: 0 10px;
  font-size: 1.5rem;
  color: #868c92;
  text-decoration: none;
  font-family: '"Libre Baskerville", serif';

}

.social-media .icons a:hover {
  color: #b8c4d1;
  font-family: '"Libre Baskerville", serif';

}

.faq-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-section ul li {
  margin: 5px 0;
}

.faq-section ul li a,
.about-us a {
  color: #d7d7d7;
  text-decoration: none;
}

.faq-section ul li a:hover,
.about-us a:hover {
  text-decoration: underline;
  font-family: '"Libre Baskerville", serif';

}

.faq-section p a {
  font-weight: bold;
  color: #d6d6d6;
  font-family: '"Libre Baskerville", serif';
}

.faq-section p a:hover {
  text-decoration: underline;
  font-family: '"Libre Baskerville", serif';

}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Stack sections vertically */
    font-family: '"Libre Baskerville", serif';

    align-items: center;
  }

  .footer-container > div {
    margin: 20px 0; /* Add vertical spacing between sections */
    font-family: '"Libre Baskerville", serif';

  }

  .social-media .icons a {
    font-size: 1.2rem; /* Adjust icon size for smaller screens */
    font-family: '"Libre Baskerville", serif';

  }
}

@media (max-width: 480px) {
  .footer-info {
    padding: 10px; /* Reduce padding for very small screens */
    font-family: '"Libre Baskerville", serif';

  }

  .about-us h2,
  .social-media h2,
  .faq-section h2 {
    font-size: 1.25rem; /* Adjust heading size for smaller screens */
    font-family: '"Libre Baskerville", serif';

  }

  .about-us p,
  .faq-section ul,
  .faq-section p,
  .social-media .icons {
    font-size: 0.9rem; /* Adjust text size for smaller screens */
    font-family: '"Libre Baskerville", serif';

  }
}