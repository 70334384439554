.enter-otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .enter-otp-container h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-family: 'Georgia Ref', Georgia, serif;
    color: #333;
  }
  
  .enter-otp-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .enter-otp-container label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #555;
  }
  
  .enter-otp-container input {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .enter-otp-container input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .enter-otp-container button {
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .enter-otp-container button:hover {
    background-color: #0056b3;
  }
  
  .enter-otp-container a {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .enter-otp-container a:hover {
    text-decoration: underline;
  }
  