/* Glass effect for the registration card */
.glass-card {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  backdrop-filter: blur(10px); /* Background blur for glass effect */
  -webkit-backdrop-filter: blur(10px); /* Support for Safari */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Light border for glass effect */
  /* margin-top: 200px; */
}
