.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #f4f4f9, #e0e0f0);
  overflow: auto;
  font-family: "Poppins", sans-serif;
}

.header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 15px 20px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.attendanceContainer {
  display: flex;
  padding: 10px 32px;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  margin: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.attendanceContainer h1 {
  font-size: 1.2rem;
  color: #333;
}

.attendanceContainer div {
  display: flex;
  gap: 30px;
}

.attendanceContainer div button {
  border: 1px solid #007bff;
  padding: 6px 10px;
  border-radius: 5px;
  background: transparent;
  color: #007bff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.attendanceContainer div button:first-child:hover {
  background: #39ee39;
  color: white;
  border-color: #39ee39;
}

.attendanceContainer div button:hover {
  background: #ffa500;
  color: white;
  border-color: #ffa500;
}

.header h2 {
  margin: 0;
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
}

.controls {
  display: flex;
  gap: 15px;
  align-items: center;
}

.header button,
.header select,
.header a {
  padding: 8px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: fit-content;
  transition: all 0.3s ease;
}

.header button {
  background-color: #ff4d4d;
  color: white;
}

.header button:hover {
  background-color: #cc0000;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header select {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
}

.header select:hover {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header a {
  background-color: #28a745;
  color: white;
  text-decoration: none;
  width: 130px;
  text-align: center;
}

.header a:hover {
  background-color: #1e7e34;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Content Section */
.content {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.content h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Message Styling */
.nopptmessage {
  font-size: 1.2rem;
  color: #666;
  margin-top: 20px;
}

.slideDetailCont {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Slide Details */
.slideDetail {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.slideDetail:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.slideDetail h2 {
  font-size: 1.5rem;
  color: #007bff;
  width: fit-content;
  margin-bottom: 10px;
}

.slideDetail h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.slideDetail p {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.slideDetail a {
  background: #007bff;
  color: white;
  padding: 8px 20px;
  border-radius: 6px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.slideDetail a:hover {
  background: #0056b3;
}

.slideDetail .errorMsg {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 828px) {
  .content h1 {
    font-size: 1.5rem;
  }

  .header {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .header h2 {
    font-size: 1.2rem;
  }

  .slideDetailCont {
    flex-direction: column;
    gap: 20px;
  }

  .slideDetail {
    width: 90%;
  }

  .slideDetail h2 {
    font-size: 1.2rem;
  }

  .slideDetail a {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 644px) {
  .slideDetail {
    width: 100%;
  }

  .content h1 {
    font-size: 1.2rem;
  }

  .header button,
  .header select,
  .header a {
    width: 100%;
    margin-bottom: 10px;
  }
}
