.overlay {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}

.signupWrapper {
  width: 50%;
  z-index: 101;
  margin: 50px auto;
  position: relative;
  height: 80%;
}

.signupWrapper input {
  margin-top: 0px !important;
}

.emailInput select {
  /* appearance: none; Remove default styling */
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10; /* Ensure dropdown appears */
  background: white;
  width: 100%;
  color: black;
  outline: none;
}

.signupBox h2 {
  font-size: 24px;
}

.signupBox {
  z-index: 102;
  background: #6169d8;
  opacity: 1;
  width: 100%;
  color: white;
  margin: 0 auto;
  padding: 40px;
  border-radius: 10px;
}

.passwordInput,
.emailInput,
.nameInput {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  padding: 0 15px;
}

.passwordInput svg,
.emailInput svg,
.nameInput svg {
  color: red;
}

.passwordInput input,
.emailInput input,
.nameInput input {
  width: 100%;
  outline: none;
  color: black;
  padding: 8px;
}

input::placeholder {
  color: black;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttonContainer button {
  background: red;
  padding: 8px 20px;
  margin-top: 20px;
  border-radius: 6px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
}
