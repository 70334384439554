/* PricingTable.css */

.pricing-table {
  text-align: center;
  font-family: Arial, sans-serif;
}

.plans {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.plan {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px;
  width: 250px;
  text-align: center;
}

.plan-title {
  font-size: 1.2em;
  margin-bottom: 10px;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.plan-price {
  display: flex;
  justify-content: center;
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.save {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.plan-details {
  list-style-type: square; /* This ensures bullets are displayed */
  padding-left: 20px; /* This adds space between the bullet and the text */
  margin-bottom: 20px;
  text-align: left;
}

.plan-details li {
  margin-bottom: 10px;
}

.plan-button {
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plan-button:hover {
  background-color: #e60000;
}

.plan-title.yearly {
  background-color: #ff4d4d;
  color: white;
}

.plan-title.half-yearly {
  background-color: #4d4dff;
  color: white;
}

.plan-title.quarterly {
  background-color: #008000;
  color: white;
}

.plan-title.monthly {
  background-color: #b366ff;
  color: white;
}

.plan-title.free {
  background-color: #4d4d4d;
  color: white;
}

.contact-info {
  margin-top: 40px;
}

.contact-info p {
  margin: 2px 0;
}

.early-bird-notice {
  font-size: 1em;
  color: #e60000;
  font-weight: bold;
  margin-top: 10px;
}

/* Modal Beautification */

.custom-radio .form-check-input {
  width: 20px;
  height: 20px;
  border: 2px solid #007bff; /* Solid outline */
  border-radius: 50%;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-radio .form-check-input:checked {
  background-color: #007bff; /* Fill color when checked */
  border-color: #007bff;
}

.custom-radio .form-check-input:hover {
  border-color: #0056b3; /* Slightly darker border on hover */
}

.custom-radio .form-check-label {
  font-size: 1rem;
  cursor: pointer;
}

.custom-radio {
  display: flex;
  align-items: center; /* Aligns radio button and label vertically */
}
