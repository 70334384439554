/* UploadVideo.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
    position: relative;
}

.closebutton {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.progressContainer {
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    height: 40px;
    text-align: center;
    font-weight: bold;
  }
  
  .progressBar {
    height: 100%;
    width: 0;
    background-color: red;
    border-radius: 5px;
    transition: width 0.4s ease-in-out;
  }
  
  .progressContainer p {
    position: absolute;
    width: 100%;
    top: 10px;
  }
  

.modaltitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.inputfield {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit{
    background: red;
    color: white;
    margin-top: 15px;
    padding: 8px 15px;
    border-radius: 6px;
    letter-spacing: 1px;
}