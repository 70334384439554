
.forgot-password-container{
    width: 500px;
    padding: 10px;
    border-radius: 10px;
    background-color: #383737;
    margin: auto;
}
.forgot-password-container h2{
    text-align: center;
    color:white;
}
.forgot-password-container form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
form input{
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
}
form a{
    color: rgb(153, 150, 150);
}
form .routes{
    display: flex;
    justify-content: space-between;
}