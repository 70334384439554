.flipbook-buttons-container * {
  box-sizing: border-box !important;
  margin: 0 !important;
}

button {
  all: unset; /* Reset default button styles */
  cursor: pointer;
}

/* Wrapper to hold everything */
.flipbook-wrapper {
  position: relative;
  margin: 20px 0;
  /* Center the wrapper itself */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container around the normal flipbook */
.flipbook-container {
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  /* You can set a width if you wish, or let the content size it */
}

/* 
    When on Page 1 (currentPage === 0), 
    we shift the flipbook so that the right page is centered
  */
.flipbook-container.notOpened {
  justify-content: flex-start; /* Move the book's cover to the right side */
  margin-left: 0%; /*Adjust as needed for your layout */
  margin-right: 20%;
}

/* 
    When NOT on Page 1, 
    we center the entire flipbook 
  */
.flipbook-container.opened {
  justify-content: center !important;
  margin-left: 0;
}

/* Center the actual flipbook block */
.normal-flipbook {
  margin: 0 auto;
}

/* Fullscreen overlay covers the entire screen */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Close (contract) button in fullscreen */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ff4444;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10000;
}

/* Wrapper for flipbook in fullscreen mode */
.fullscreen-flipbook {
  width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Center the flipbook in fullscreen mode as well */
.fullscreen-flipbook-content {
  margin: 0 auto;
}

/* The "Expand" (go fullscreen) button */
.fullscreen-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

/* Image inside flipbook (non-fullscreen) */
.flipbook-img {
  max-width: 100%;
  height: auto;
}

/* Image inside fullscreen flipbook */
.flipbook-img-fullscreen {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Page container styling */
.flipbook-page {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  overflow: hidden;
  position: relative;
}

/* Simple page number styling */
.flipbook-page-number {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

/* Add to Flipbook.css */
/* Updated CSS rules in Flipbook.css */
.flipbook-img,
.flipbook-img-fullscreen {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;
}

/* Add this new rule for canvas elements */
canvas {
  image-rendering: optimizeQuality !important;
}

.flipbook-buttons-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 100;
  /* Add background if needed to ensure visibility */
  background: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 24px;
}

/* Remove conflicting grid styles */
.flipbook-buttons-container {
  display: flex !important; /* Override any grid display */
  grid-auto-flow: unset;
  grid-gap: unset;
}

/* Add to prevent buttons from shrinking */
.flipbook-buttons-container button {
  flex-shrink: 0;
}

.flipbook-buttons-container button:not(:last-child) {
  margin-right: 10px;
}

.read-pdf-button {
  /* white-space: nowrap; */
  padding: 8px 16px;
  height: 40px;
}

.read-pdf-button:hover {
  background: #e03333; /* Darken on hover */
}

@media (max-width: 768px) {
  .flipbook-buttons-container {
    flex-direction: column;
    gap: 8px;
    right: 5px;
    top: 5px;
  }

  .read-pdf-button {
    font-size: 14px;
    padding: 6px 12px;
    height: auto;
  }
}
