/* Table Styling */
.galleryTable {
    margin: 20px 0;
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
}

.galleryTable th,
.galleryTable td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.galleryTable th {
    background: #343a40;
    color: white;
    font-size: 18px;
}

.galleryTable tr:nth-child(even) {
    background: #f8f9fa;
}

/* Row Styling */
.galleryRow {
    transition: all 0.3s ease-in-out;
}

.galleryRow:hover {
    background: #e9ecef;
    transform: scale(1.02);
}

/* Image Name Cell */
.imageNameCell {
    font-weight: bold;
    color: #495057;
}

/* Actions Cell */
.actionsCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* See Image Button */
.seeImagesButton {
    padding: 8px 12px;
    background: #17a2b8;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    transition: all 0.3s;
}

.seeImagesButton:hover {
    background: #138496;
}

/* Assign Today Button */
.assignTodayButton {
    padding: 8px 16px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s;
    text-decoration: none;
}

.assignTodayButton:hover {
    background: #218838;
}

/* Delete Button */
.deleteBTN {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
}

.deleteBTN svg {
    color: #dc3545;
    transition: all 0.3s;
}

.deleteBTN:hover svg {
    color: #c82333;
    transform: scale(1.2);
}

/* Responsive Design */
@media (max-width: 768px) {
    .galleryTableWrapper {
        padding: 10px;
    }

    .galleryTable th,
    .galleryTable td {
        padding: 10px;
        font-size: 14px;
    }

    .actionsCell {
        flex-direction: column;
        gap: 5px;
    }

    .seeImagesButton,
    .assignTodayButton {
        width: 100%;
        text-align: center;
    }

    .deleteBTN svg {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 480px) {

    .galleryTable th,
    .galleryTable td {
        font-size: 12px;
        padding: 8px;
    }

    .assignTodayButton {
        padding: 6px 12px;
    }

    .seeImagesButton {
        padding: 6px 10px;
    }

    .deleteBTN svg {
        width: 18px;
        height: 18px;
    }
}