.icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
}

.icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
}

.icon,
.icon_header {
    margin-right: 5px;
}

.close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
}

.grid-container {
    display: grid;
    height: 100vh;
}

/* Main  */
.main-container {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    background: #fcfbfb;
}

.main-title {
    display: flex;
    justify-content: space-between;
}

.main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    color: white;
}

.card:first-child {
    background-color: #2962ff;
}

.card_icon {
    color: white;
}

.card:nth-child(2) {
    background-color: #ff6d00;
}

.card:nth-child(3) {
    background-color: #2e7d32;
}

.card:nth-child(4) {
    background-color: #d50000;
}

.card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-inner>.card_icon {
    font-size: 25px;
}

.charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
}

/* End Main  */

/* Medium <= 992px */

@media screen and (max-width: 992px) {
    .grid-container {
        grid-template-columns: 2fr;
        grid-template-rows: 0.2fr 3fr;
    }
}

.search-teacher,
.search-school {
    width: 100%;
    border: 2px solid blue;
    padding: 10px 20px;
    border-radius: 10px;
    outline: none;
    margin: 20px 0;
}

.school-list p {
    padding: 10px;
    border-radius: 6px;
}

.filterInputs {
    padding: 6px 15px;
    width: 30%;
    margin: 10px auto;
}

.p-12 {
    width: 100%;
}

.p-6 {
    margin: 0 auto;
}

.table-filter-container th,
.table-filter-container td {
    padding: 10px;
}

.userFilterInputs {
    display: flex;
}

.toggle-button button {
    border: 2px solid blue;
    padding: 8px 20px;
    border-radius: 6px;
}

.overlayContainer {
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    padding: 30px;
    background: white;
    border-radius: 10px;
    position: relative;
}

.overlay th {
    background: #245dfc;
    color: white;
}

.overlay table {
    border-radius: 10px;
    overflow: hidden;
}

.overlay th,
.overlay td {
    padding: 10px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
}

.download-btn{
    position: absolute;
    top: 10px;
    left: 15px;
    padding: 5px 15px;
}

/* Small <= 768px */

@media screen and (max-width: 768px) {
    .main-cards {
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 0;
    }

    .charts {
        grid-template-columns: 1fr;
        margin-top: 30px;
    }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
    .hedaer-left {
        display: none;
    }
}