/* QuestionPageDesktop.css */

/* Container */
.question-page-desktop-container {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Ensure no overflow for the main container */
}

/* Content */
.desktop-question-content {
  flex-grow: 1;
  margin-right: 20px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto; /* Ensure content scrolls if needed */
  width: 75%; /* Adjust as needed */
}

.desktop-question-header {
  text-align: left;
}

.desktop-voice-button {
  font-size: 14px;
  margin-left: 10px;
}

.desktop-question-text {
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.desktop-question-text-bold {
  text-align: left;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.desktop-question-image {
  max-width: 30%;
  height: auto;
  margin-top: 10px;
}

.desktop-options-form {
  text-align: left;
}

.desktop-option-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.desktop-option-item .form-check-input {
  vertical-align: middle;
  margin-right: 5px;
  border: 1px solid black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 2px;
}

.desktop-answer-explanation-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 10px;
  align-items: center;
}

.desktop-explanation-text-container-left,
.desktop-explanation-text-container-right {
  flex: 1;
  min-width: 0; /* Ensures flex items don't grow beyond their containers */
  padding: 0 10px;
  overflow: hidden; /* Ensures text doesn't overflow the container */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desktop-answer-image-container {
  flex-shrink: 0; /* Prevents the image from shrinking */
  margin: 0 10px;
}

.desktop-answer-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.desktop-explanation-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

/* Navigation Box */
.desktop-navigation-box {
  width: 25%; /* Adjust as needed */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  padding-bottom: 50px;
  box-sizing: border-box;
  background-color: #f8f9fa;
  overflow-y: auto; /* Ensure navigation box scrolls if needed */
}

.desktop-navigation-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  gap: 20px;
}

.desktop-navigation-button {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 20%;
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: black;
  background-color: white;
}

.desktop-navigation-button.active {
  background-color: green;
  color: white;
}

img {
  cursor: pointer;
}

img:fullscreen {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*--------------------------------------------------------------------------------*/

.pdf-flipbook-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.pdf-flipbook {
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.pdf-page {
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-pdf__Page {
  margin: 10px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}