.container,
.NoDatacontainer {
    width: 100vw;
    margin: auto;
    padding: 20px;
    height: 100vh;
    background: #10053a;
}

.NoDatacontainer {
    text-align: center;
}

.back {
    position: fixed;
    background: red;
    top: 20px;
    color: white;
    border-radius: 6px;
    left: 20px;
}

.back a {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    gap: 6px;
}

.heading {
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
}

.tableWrapper {
    overflow-x: auto;
}

.tableWrapper tbody {
    background: rgb(213, 207, 207);
    transition: 1s;
}

.table {
    width: 100%;
    border-collapse: collapse;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
}

.table th,
.table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #4caf50;
    color: white;
}

.table tr:hover {
    background-color: #f5f5f5;
}

.noDataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin-top: 50px;
}

.noDataText {
    font-size: 18px;
    font-weight: 500;
    color: #555;
    margin-top: 10px;
}

.section h3{
    color: white;
}

.showResult {
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
    padding: 10% 20%;
}

.clickableRow {
    cursor: pointer;
    transition: background 0.2s;
}

.clickableRow:hover {
    background-color: #f0f0f0;
}

/* Modal Styling */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    background: white;
    padding: 20px;
    width: 50%;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

.modalContent h3{
    padding: 10px;
    text-align: center;
}

.closeButton {
    background: transparent;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    color: red;
}

.noData {
    text-align: center;
    font-weight: bold;
    color: gray;
}

@media (max-width: 768px) {

    .table th,
    .table td {
        font-size: 14px;
        padding: 10px;
    }
}

@media (max-width: 480px) {

    .table th,
    .table td {
        font-size: 12px;
        padding: 8px;
    }

    .heading {
        font-size: 20px;
    }
}