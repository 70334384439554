/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;





body,html {
  font-family: Libre Baskerville;
  margin: 0 !important;
  padding:0 !important;
  overflow-x:hidden !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link{
  color:red !important;
}

.navbar-brand{
  color:black !important;
}

h1{
  font-size:20px ;
}

.flex-container{
  display:flex !important;
}

.btn{
  background-color:red !important;
  color:white !important;
}

select{
  box-shadow:none !important;
  border-color:black !important;
}

.fa-plus{
  color:green;
  margin: 5px;
}

.fa-minus{
  color:red;
  margin: 5px;
}

.fa-trash{
  color:red;
}

input{
  margin-top: 10px !important;
  box-shadow: none !important;
}

.adminfunctions{
  padding:10px;
  background-color:rgb(1,1,77);
}
.adminfunctions li{
  list-style-type:none !important;
  display:inline !important;
  color:white !important;
}

@media (max-width:550px){
  .adminfunctions li{
    display:flex !important;
    justify-content: center !important;
  }
}

.adminfunctions li a{
  color:white !important;
  padding: 20px;
}
td,th,tr{
  text-align:center;
}
/*---------------------------------------------------*/
/* CSS for Toggle Button */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.radio-bottom-margin {
  margin-bottom: 10px; /* Adjust as needed */
}



::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: #ca0303;
}
@media (max-width: 640px) {
  .tw-scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}
.tw-scrollbar-hide::-webkit-scrollbar {
  display: none;
}