.ShowImagesByIdContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100vw;
  min-height: 100vh;
  background: #f0f2f5;
  padding: 2rem;
}

.title {
  color: #2c3e50;
  font-size: 2.5rem;
  text-align: center;
  margin: 1rem 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.ImagesWrapper {
  display: flex;
  gap: 2rem;
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
}

.ImageCont {
  flex: 3;
  height: 70vh;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.ImageCont:hover {
  transform: translateY(-5px);
}

.media {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease;
}

.slideIn {
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fullScreenBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 0.8rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.fullScreenBtn:hover {
  background: #007bff;
  transform: scale(1.1);
}

.icon {
  width: 24px;
  height: 24px;
  color: #007bff;
}

.fullScreenBtn:hover .icon {
  color: white;
}

.imageButtons {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-height: 70vh;
  overflow-y: auto;
}

.slideButtonContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideButton {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 12px;
  background: #f8f9fa;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.slideButton:hover {
  background: #e9ecef;
  transform: translateY(-3px);
}

.slideNumber {
  font-weight: 600;
  color: #495057;
}

.active .slideButton {
  background: #007bff;
  transform: scale(1.1);
}

.active .slideNumber {
  color: white;
}

.activeIndicator {
  position: absolute;
  bottom: -8px;
  width: 6px;
  height: 6px;
  background: #007bff;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

.checkboxLabel {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #007bff;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.checkmark:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 4px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:checked ~ .checkmark {
  background-color: #007bff;
}

input:checked ~ .checkmark:after {
  opacity: 1;
}

@media (max-width: 768px) {
  .ImagesWrapper {
    flex-direction: column;
    padding: 1rem;
  }

  .ImageCont {
    height: 50vh;
  }

  .imageButtons {
    max-height: 40vh;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  }

  .title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .ShowImagesByIdContainer {
    padding: 1rem;
  }

  .slideButton {
    width: 40px;
    height: 40px;
  }

  .checkmark {
    width: 25px;
    height: 25px;
  }
}
