#round {
    border-radius : 50px;
    border:"1px solid black";
    width: 300px; /* Set your desired width */
  height: 400px; /* Set your desired height */
  /* overflow: hidden; */
}

.quiz-card {
  position: relative;
  overflow: hidden; /* Ensures content stays within the card */
  cursor: pointer;
}

.quiz-name-hover {
  position: absolute;
  bottom: 50;
  top:50;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;
  text-align: center;
  padding: 10px;
  font-family: "Georgia Ref", Georgia, serif;
  font-weight: bold;
  font-size: 20px;
  display: none; /* Hidden by default */
}

.quiz-card:hover .quiz-name-hover {
  display: block; /* Show on hover */
}
