/* .page {
    background-image: url(https://images.unsplash.com/photo-1603513492128-ba7bc9b3e143?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8cGFwZXIlMjB0ZXh0dXJlfGVufDB8fDB8&ixlib=rb-1.2.1&w=1000&q=80);
    border: 1px solid rgb(198, 194, 194);
      
      box-sizing: border-box;
      box-shadow: 0 1.5em 3em -1em rgb(70, 69, 69);
  }
  
  .container-md{
    width:600px;
  }
  .page-cover{
   background-image: url('https://i.imgur.com/mzp2Uub.jpg');
   background-size: cover;
  }
  
  .page-cover-bottom {
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
      10px 0 8px 0px rgba(0, 0, 0, 0.4);
  }
  .page-cover-top {
    box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5),
      -2px 0 5px 2px rgba(0, 0, 0, 0.4);
  }
  .page-cover {
    color: hsl(35, 35, 35);
    border: solid 2px hsl(35, 20, 50);
  } */

/* Wrapper to ensure full central alignment */
.flipbook-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Flipbook Container */
.flipbook-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  overflow: hidden;
  transition: justify-content 0.5s ease-in-out;
  position: relative;
}

/* ✅ Align Flipbook Properly After Opening */
.flipbook-container.opened {
  justify-content: center !important; /* ✅ Centers flipbook after opening */
}

/* Flipbook Page */
.flipbook-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  text-align: center;
}

/* Flipbook Image */
.flipbook-img {
  width: 100%;
  height: auto;
  max-width: 280px;
  border-radius: 5px;
  object-fit: contain;
}

/* Page Number */
.flipbook-page-number {
  font-size: 14px;
  color: gray;
  font-weight: bold;
  margin-top: 5px;
}

/* Full-Screen Button */
.fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1000; /* ✅ Ensure button is visible */
}

.fullscreen-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Full-Screen PDF Container */
.fullscreen-pdf-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Close Button in Fullscreen */
.close-fullscreen-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: red;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10001;
}

.close-fullscreen-button:hover {
  background: darkred;
}

/* PDF Iframe */
.pdf-iframe {
  width: 90vw;
  height: 90vh;
  border: none;
}

/* Full-Screen Mode */
.flipbook-container:fullscreen {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
  height: 100vh !important;
}


.notOpened{
  margin-left: 8%;
}


/* ✅ Scale the Flipbook When in Fullscreen Mode */
/* :fullscreen .flipbook-container,
:-webkit-full-screen .flipbook-container,
:-moz-full-screen .flipbook-container,
:-ms-fullscreen .flipbook-container {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* Scale Flipbook Pages */
/* :fullscreen .flipbook-page img,
:-webkit-full-screen .flipbook-page img,
:-moz-full-screen .flipbook-page img,
:-ms-fullscreen .flipbook-page img {
  width: 90vw !important;
  height: auto !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  object-fit: contain;
} */

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .flipbook-container {
    padding: 5px;
  }
  .flipbook-img {
    max-width: 250px;
  }

}

